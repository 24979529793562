.HTMLArea h1,
.HTMLArea h2,
.HTMLArea h3,
.HTMLArea h4,
.HTMLArea h5,
.HTMLArea h6{
 font-size: 20px;
 font-weight: 700;
 margin-bottom: 10px;
}

.HTMLArea ul, .HTMLArea ol{ 
 padding-left: 20px;
}

.HTMLArea ul li,
.HTMLArea ol li{
 margin-bottom: 20px;
}

.HTMLArea ul li > ul,
.HTMLArea ol li > ol{
 margin-top: 10px;
}

.HTMLArea ul li > ul li,
.HTMLArea ol li > ol li{
 margin-bottom: 10px;
}

.HTMLArea p{
 margin-bottom: 20px;
 text-align: left;
}

.HTMLArea a{
 text-decoration: underline;
 color: #E8036F;
}

